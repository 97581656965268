import {BaseModel} from './base.model';

import {AppDate} from './date.model';
import {NotificationData} from './notification-data.model';

export type NotificationType = 'SHIP_CONFIRM'
    | 'SHIPMENT_NOTIFICATION'
    | 'SHIPMENT_UPDATE_NOTIFICATION'
    | 'ORDER_ITEM_CANCELLED'
    | 'TIER_CHANGED'
    | 'AS_READY'
    | 'AS_CANCELLED'
    | 'OW_MOQ_MET'
    | 'OW_MOQ_NOT_MET'
    | 'DOA_ORDER'
    | 'EST_SHIP_DATE_UPDATED'
    | 'DOWNLOAD_READY'
    | 'ORDER_ITEM_CUT';


export class Notification extends BaseModel {
    public id: number;
    public user_id: number;
    public is_unread: boolean;
    public message: string;
    public notificationData: NotificationData;
    public created_at: AppDate;
    public read_at: string;

    public type: NotificationType;

    constructor(data) {
        super(data, {
            num: ['id', 'user_id'],
            bool: ['is_unread'],
            date: ['created_at', 'read_at']
        });

        if (data.data) {
            this.notificationData = new NotificationData(data.data);
        }


        if (data.tpl_key) {
            this.type = data.tpl_key as NotificationType;
        }

        if (!this.message) {
            this.message = '';
        }
    }


    get isMOQMet(): boolean {
        return this.type === 'OW_MOQ_MET'
    }

    get isMOQNotMet(): boolean {
        return this.type === 'OW_MOQ_NOT_MET';
    }

    get isPriceChangedNotification(): boolean {
        return this.type === 'TIER_CHANGED'
    }

    get isOrderItemCancelledNotification(): boolean {
        return this.type === 'ORDER_ITEM_CANCELLED';
    }

    get isOrderItemCutNotification(): boolean {
        return this.type === 'ORDER_ITEM_CUT';
    }

    get isShipmentDateChanged(): boolean {
        return this.type === 'EST_SHIP_DATE_UPDATED' || this.type === 'SHIPMENT_UPDATE_NOTIFICATION'
    }

    get isShipmentChangedNotification(): boolean {
        return this.type === 'SHIPMENT_NOTIFICATION'
    }

    get isShipmentConfirmationNotification(): boolean {
        return this.type === 'SHIP_CONFIRM'
    }

    get isAutoshipReadyNotification(): boolean {
        return this.type === 'AS_READY'
    }

    get isAutoshipCancelledNotification(): boolean {
        return this.type === 'AS_CANCELLED';
    }

    get isDoaNotification(): boolean {
        return this.type === 'DOA_ORDER';
    }

    get isDownloadReady(): boolean {
        return this.type === 'DOWNLOAD_READY';
    }

    get productImageUrl(): string {
        return this.notificationData?.product?.imageUrl ||
            '/assets/img/app/no-image.png'
    }

    get productLabel() {
        return this.notificationData?.product?.label || '';
    }

    get productSKU(): string {
        return this.notificationData?.product?.sku_number  || '';
    }

    get productSupplierId(): string {
        return this.notificationData?.product?.supplier_id  || '';
    }

    get productWindowName(): string {
        return this.notificationData?.product?.window_name  || '';
    }

    get downloadLabel(): string {
        return this.notificationData?.label || '';
    }

    get downloadUrl(): string {
        return this.notificationData?.url || '';
    }

}
