<form [formGroup]="contactForm" class="form">
    <ng-container formArrayName="contact_details">
        <ng-container *ngFor="let contact_detail of contact_details.controls; let i = index">
            <div class="form-row" [formGroup]="contact_detail">
                <div class="col">
                    <div class="form-group" [ngClass]="validationClassName(contact_detail.controls.contact)">
                        <label class="form-control-label font-weight-bold" [for]="'contact_' + i">
                            <ng-container *ngIf="contact_detail.controls.main.value; else additionalContact">Main Contact</ng-container>
                            <ng-template #additionalContact>Additional Contact</ng-template>
                        </label>
                        <input class="form-control" [id]="'contact_' + i" maxlength="255" formControlName="contact" trim placeholder="Enter Name" (input)="onInputChange($event)">
                    </div>
                </div>
                <div class="col">
                    <div class="form-group" [ngClass]="validationClassName(contact_detail.controls.email)">
                        <label class="form-control-label font-weight-bold" [for]="'email_' + i">Email Address</label>
                        <input class="form-control" [id]="'email_' + i" maxlength="255" formControlName="email" trim placeholder="Enter Email Address" (input)="onInputChange($event)">
                        <small class="text-danger" *ngIf="contact_detail.controls.email.errors?.pattern">Please enter a valid email address.</small>
                    </div>
                </div>
                <div class="col-1">
                    <div class="d-flex h-100">
                        <button  (click)="onRemoveContactDetail(i)" class="btn btn-icon w-100 h-100"
                                 title="Remove Contact">
                            <i class="fal fa-trash-alt"></i>
                        </button>
                    </div>
                </div>
            </div>

            <hr *ngIf="i === 0" class="mt-0">
        </ng-container>
    </ng-container>
</form>


<button type="button" class="btn btn-outline-primary mb-4" [disabled]="contact_details.controls.length === numberOfAdditionalFields" (click)="onAddContactDetail()">
    Add Additional Contact +
</button>
