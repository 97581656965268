import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {catchError, map, tap} from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AppSettings } from '../app.settings';
import { ToastrService } from 'ngx-toastr';

import { Response } from '../models/response.model';
import { User } from '../models/user.model';
import { LocalStorageService, U_SSO_URL, U_UTC_OFFSET } from './local-storage.service';
import {isEmptyArray} from '../shared/utils';
import {ConfigService} from './config.service';
import {Order} from '../models/order.model';
import {QUOTE_STATE} from '../constants/order-states';
import {Address} from '../models/address.model';
import {UserCountry} from '../interfaces/user-country';
import {API_URL} from '../constants/api-urls';

const ORDERING_ROLE = 'ORDERING_ROLE';
const AUTOSHIP_ROLE = 'AUTOSHIP_ROLE';
const WSLR_ORDER_VIEW_ROLE = 'WSLR_ORDER_VIEW_ROLE';
const WSLR_ORDER_EDIT_ROLE = 'WSLR_ORDER_EDIT_ROLE';
const WSLR_ADDR_VIEW_ROLE = 'WSLR_ADDR_VIEW_ROLE';
const WSLR_ADDR_EDIT_ROLE = 'WSLR_ADDR_EDIT_ROLE';
const WSLR_ADDR_DEFAULT_ROLE = 'WSLR_ADDR_DEFAULT_ROLE';
const IMP_ORDER_VIEW_ROLE = 'IMP_ORDER_VIEW_ROLE';
const IMP_ORDER_EDIT_ROLE = 'IMP_ORDER_EDIT_ROLE';

const OD_ONLY_ROLE = 'OD_ONLY_ROLE';
const SAP_ROLE = 'SAP_ROLE';
const DECISION_POINTS_ROLE = 'DECISION_POINTS_ROLE';
const CC_PAYMENTS_ROLE = 'CC_PAYMENTS_ROLE';
const ADMIN_REPORTING_ROLE = 'ADMIN_REPORTING_ROLE';
const REPORTING_ROLE = 'REPORTING_ROLE';
const WSLR_REPORTING_ROLE = 'WSLR_REPORTING_ROLE';
const VENDOR_REPORT_ROLE = 'VENDOR_REPORT_ROLE';

const EMPLOYEE_ROLE = 'EMPLOYEE_ROLE';
const EMPLOYEE_ADMIN_ROLE = 'EMPLOYEE_ADMIN_ROLE';
const EMPLOYEE_LIMITED_ROLE = 'EMPLOYEE_LIMITED_ROLE';
const WHOLESALER_ROLE = 'WHOLESALER_ROLE';
const WHOLESALER_ADMIN_ROLE = 'WHOLESALER_ADMIN_ROLE';
const WHOLESALER_LIMITED_ROLE = 'WHOLESALER_LIMITED_ROLE';
const VENDOR_ROLE = 'VENDOR_ROLE';
const VENDOR_ADMIN_ROLE = 'VENDOR__ADMIN_ROLE';
const VENDOR_LIMITED_ROLE = 'VENDOR_LIMITED_ROLE';

const LIMITED_USER_ROLE = 'LIMITED_USER_ROLE';
const ORDERING_USER_ROLE = 'ORDERING_USER_ROLE';
const ORDER_ADMIN_ROLE = 'ORDER_ADMIN_ROLE';
const RESTRICTED_USER_ROLE = 'RESTRICTED_USER_ROLE';
// if (this.onlyRole('LIMITED_USER_ROLE') === true) {
//     this.isLimited = true;
// } else if (this.onlyRole('ORDERING_USER_ROLE') === true) {
//     this.isWholeSeller = true;
// } else if (this.hasRole('ORDER_ADMIN_ROLE') === true) {
//     this.isEmployee = true;
// } else if (this.hasRole('IMP_ORDER_VIEW_ROLE') === true) {
//     this.isWholeSellerMultiple = true;
// }

@Injectable()
export class AuthService extends BaseService {

    public user: User;

    private _canOrder: boolean;
    private _canSeeBuyingWindows: boolean;
    private _canAutoship: boolean;
    private _canEditSap: boolean;
    private _canEditDecisionPoints: boolean;
    private _canEditCreditCards: boolean;
    private _canSeeReports: boolean;
    private _canSeeAddresses: boolean;
    private _canEditAddresses: boolean;
    private _canSetDefaultAddresses: boolean;

    private _isEmployee: boolean;
    private _isWholesaler: boolean;
    private _isWholeSalerMultiple: boolean;
    private _isWholeSalerEditor: boolean
    private _isVendor: boolean;
    private _isRestricted: boolean;
    private _userCountries: UserCountry[];

    constructor(
        protected http: HttpClient,
        protected appSettings: AppSettings,
        protected toastr: ToastrService,
        private localStorageService: LocalStorageService,
        private config: ConfigService
    ) {
        super('/account', http, appSettings, toastr);
        console.log('AuthService constructor');
    }

    validate(): Observable<User> {
        // const token: string = this.localStorageService.getItem(U_TOKEN);
        // if (!token) {
        //     return of(null);
        // }
        //
        const url = `${API_URL}/account/validate`;
        return this.http.get<Response>(url)
            .pipe(
                map(response => {
                    const result = (response as Response);

                    if (result.data) {
                        this.user = new User(result.data);
                        return this.user;
                    }

                    return null;
                }),
                tap( user => {
                    this.parseRoles(user)
                })
            );
    }

    login(credentials: any): Observable<any[] | User> {
        return this.http.post<Response>(this.apiURL + '/login', credentials)
            .pipe(
                map(response => {
                    this.user = new User((response as Response).data);
                    return this.user;
                }),
                catchError(this.handleError('AuthService::login', null)),
                tap( user => this.parseRoles(user))
            );
    }

    logout(): Observable<boolean> {
        return this.http.get(this.apiURL + '/logout')
            .pipe(
                map(() => {
                    // if (this.user.sso) {
                    //     document.cookie = 'sso_user_token=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                    //     window.location.href = 'https://www.abmarketing.com/'; // TODO migrate to 'u-sso-url'
                    // }

                    this.user = null;
                    return true;
                }),
                catchError(this.handleError('AuthService::logout', false)),
                tap( logoutResult => {
                    if (logoutResult) {
                        this.resetRoles();
                    }
                })
            );
    }

    resetPassword(data: any): Observable<any> {
        return this.http.post(this.apiURL + '/reset-password', data)
            .pipe(
                map(response => {
                    return response;
                }),
                catchError(this.handleError('AuthService::resetPassword', []))
            );
    }

    public get isWholeSaler(): boolean {
        return this._isWholesaler;
    };
    public get isWholeSalerMultiple(): boolean {
        return this._isWholeSalerMultiple;
    };


    // permissions
    public get canOrder(): boolean {
        return this._canOrder;
    }

    public get canChangeOrderType(): boolean {
        return this._isEmployee;
    }

    public get canSeeReports(): boolean {
        return this._canSeeReports;
    }

    public get canSeeClearance(): boolean {
        return false;
    }

    public get canSeeTruckGraphics(): boolean {
        return false;
    }

    public get canAddAddress(): boolean {
        return this._canEditAddresses;
    }

    public canEditAddresses(address: Address): boolean {
        if (!address) {
            return false;
        }

        return this._canEditAddresses && address.editable &&
            (this._canSetDefaultAddresses || address.entity_id === this.user.entity_id);
    }

    public canMoveAddress(address: Address): boolean {
        if (!address || address.is_default) {
            return false;
        }

        if (this._canSetDefaultAddresses) {
            return true;
        }

        const addressEntity = address.entity_id || 0;
        return  this._canEditAddresses && addressEntity === this.user.entity_id;
    }

    public get canSeeAddresses(): boolean {
        return this._canSeeAddresses;
    }

    public get canAutoship(): boolean {
        return false;
        // return this._canAutoship;
    }

    public get canBuyingWindow(): boolean {
        return this._canSeeBuyingWindows;
    }


    public get canShowSAP(): boolean {
        return true;
    }

    public get canManagePaymentMethods(): boolean {
        return this._canEditSap;
    }


    public get canEditCreditCard(): boolean {
        return this._canEditCreditCards && this.config.isCredCardPaymentActive;
    }

    public get canSeeBudgets(): boolean {
        return false;
        // return this.canOrder && !this.isEmployee;
    }

    public get canSeeTransactions(): boolean {
        return false;
        // return this.canOrder && this._canEditCreditCards && this.config.isCredCardPaymentActive;
    }

    public get canEditDecisionPoints(): boolean {
        return false;
        // return this._canEditDecisionPoints;
    }

    public get canSeePaymentMethods(): boolean {
        return this.canOrder && (this.canShowSAP || (this._canEditCreditCards && this.config.isCredCardPaymentActive));
    }


    public get canSeeOrders(): boolean {
        return this.canOrder;
    }

    public get authenticated(): boolean {
        return !!this.user;
    }

    public get canSeeFavorites(): boolean {
        return this.config.features.showFavorites === true;
    }

    public get canSeeNotifications(): boolean {
        return this._canOrder;
    }

    public get canSeeOrderSummaries(): boolean {
        return this.isWholeSaler;
    }

    public get canSetDefaultAddresses(): boolean {
        return  this._canSetDefaultAddresses;
    }

    public get canSeeRepairRequests(): boolean {
        return false;
        // return true;
    }

    public get canAddRepairRequest(): boolean {
        return false;
        // return true;
    }

    public get isRestricted(): boolean {
        return this._isRestricted;
    }


    public resetRoles() {
        this._canOrder =  false;
        this._canSeeBuyingWindows = false;
        this._canAutoship = false;
        this._canEditSap = false;
        this._canEditDecisionPoints = false;
        this._canEditCreditCards = false;
        this._canSeeReports = false;
        this._canEditAddresses = false;
        this._canSeeAddresses = false;
        this._isRestricted = false;
    }


    private parseRoles(user: User) {
        this.resetRoles();
        this._isEmployee = this.hasRole(user, EMPLOYEE_ROLE, EMPLOYEE_ADMIN_ROLE, EMPLOYEE_LIMITED_ROLE, ORDER_ADMIN_ROLE);
        this._isWholesaler = this.hasRole(user, WHOLESALER_ROLE, WHOLESALER_ADMIN_ROLE, WHOLESALER_LIMITED_ROLE);
        this._isWholeSalerMultiple = this.hasRole(user, IMP_ORDER_VIEW_ROLE, IMP_ORDER_EDIT_ROLE);
        this._isWholeSalerEditor = this.hasRole(user, WSLR_ORDER_EDIT_ROLE);
        this._isVendor = this.hasRole(user, VENDOR_ROLE, VENDOR_ADMIN_ROLE, VENDOR_LIMITED_ROLE);
        this._isRestricted  = this.hasRole(user, RESTRICTED_USER_ROLE);

        this._canOrder = this.hasRole(user, ORDERING_ROLE);
        if (this.hasRole(user, LIMITED_USER_ROLE, EMPLOYEE_LIMITED_ROLE, WHOLESALER_LIMITED_ROLE, VENDOR_LIMITED_ROLE)) {
            // compatibility with old rights
            this._canOrder = false;
        }

        // this._canSeeReports = this.hasRole(user, ADMIN_REPORTING_ROLE, REPORTING_ROLE, WSLR_REPORTING_ROLE, VENDOR_REPORT_ROLE);
        // this._canSeeReports = this.hasRole(user, ADMIN_REPORTING_ROLE);
        this._canSeeReports = false;
        // this._canAutoship = this.hasRole(user, AUTOSHIP_ROLE);
        this._canAutoship = false;
        this._canSeeAddresses = (this.isEmployee && this._canOrder) || this.hasRole(user, WSLR_ADDR_VIEW_ROLE);
        this._canEditAddresses = (this.isEmployee && this._canOrder) || this.hasRole(user, WSLR_ADDR_EDIT_ROLE);
        this._canSetDefaultAddresses = (this.isEmployee && this._canOrder) || this.hasRole(user, WSLR_ADDR_DEFAULT_ROLE);
        this._canEditCreditCards = this.hasRole(user, CC_PAYMENTS_ROLE);
        // this._canEditDecisionPoints = this.hasRole(user, DECISION_POINTS_ROLE);
        this._canEditDecisionPoints = false;
        this._canEditSap = this.hasRole(user, SAP_ROLE);
        this._canSeeBuyingWindows  = !this.hasRole(user, OD_ONLY_ROLE);

    }


    private hasRole(user: User, ...roles) {
        if (!user || isEmptyArray(user.role)) {
            return false;
        }

        return  user.role.filter( role => {
            if (roles.includes(role)) {
                return true;
            }
        }).length  > 0;
    }


    get userRole(): string {
        if (this.user.role_label) {
            return this.user.role_label;
        }
        if (this._isEmployee) {
            return 'Employee';
        } else if (this._isWholesaler) {
            return 'Wholesaler';
        } else if (this._isVendor) {
            return 'Vendor';
        }

        return '';
    }


    // Note - Vendor should be treated as an employee
    public get isEmployee(): boolean {
        return this._isEmployee || this._isVendor;
    }

    public get isVendor(): boolean {
        return this._isVendor;
    }
    public canDeleteOrder(order: Order): boolean {
        if (!order || !this.user) {
            return  false;
        }

        // user can delete only his own order
        if (order.user_id !== this.user.id) {
            return false;
        }


        if (order.isBranch || order.isCustom) {
            return false;
        }

        // user must by employee for  BW orders or BW order has no items
        if (order.isByingWindow && this.isEmployee !== true && order.item_count > 0 ) {
            return false;
        }

        return true;
    }

    public canEditOrder(order: Order) {
        if (!order) {
            return false;
        }

        if (order.id === 0) {
            return true;
        }

        if (!this.canOrder) {
            return false;
        }

        if (order.state !== QUOTE_STATE) {
            return false;
        }

        if (this.isEmployee) {
            return true;
        } else if (this._isWholesaler) {
            if (order.user_id === this.user.id) {
                return true;
            } else {
                return this._isWholeSalerEditor;
            }
        }

        return false;
    }

    public canEditOrderSettings(order: Order): boolean {
        return this.canEditOrder(order) && order.id > 0;
    }

    public canMakeActiveOrder(order) {
        if (!this.canEditOrder(order)) {
            return false;
        }

        return !order.is_active;
    }

    public canShowRecommends(order: Order): boolean {
        if (!order) {
            return false;
        }

        if (order.isByingWindow) {
            return this.isWholeSaler || order.isWSLR || order.isDecisionPoint;
        }

        return false;
    }

    public get userCountries(): UserCountry[] {
        return this.user.countries;
    }

}

