import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { OrderService } from '../../../services/order.service';
import { Order } from '../../../models/order.model';
import { OrderItem } from '../../../models/order-item.model';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {GoogleAnalytics} from '../../../services/google-analytics.service';
import {OrderItemDelivery} from '../../../models/order-item-delivery.model';
import {HelperService} from '../../../services/helper.service';

@Component({
    selector: 'app-order-item-modal',
    templateUrl: './order-item-modal.component.html',
    styleUrls: ['./order-item-modal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class OrderItemModalComponent implements  OnInit {
    order: Order;
    orderItem: OrderItem;
    delivery: OrderItemDelivery;
    quantity: number;
    hideFinancials = false;

    readonly = false;
    updateResult = false;
    showOrderSelector: boolean;

    setupOrderItem: Function;

    constructor(private orderService: OrderService,
                private helperService: HelperService,
                private activeModal: NgbActiveModal, private googleAnalytics: GoogleAnalytics) {}

    ngOnInit(): void {
        if (this.orderItem && this.orderItem.product ) {
            this.googleAnalytics.viewItem(this.orderItem.product);
        }
    }

    close() {
        this.activeModal.dismiss(this.updateResult ? this.orderItem : null);
    }

    handleUpdate(orderItem: OrderItem) {
        this.orderItem = orderItem;
        this.orderItem.changed = false;
        this.updateResult = true;
    }

    onOrderItemChanged(item: OrderItem) {
        if (item) {
            this.orderItem = item;
        } else {
            this.orderItem.changed = false;
        }
    }

    onOrderSelected(order: Order) {
        if (order && order.id !== this.order.id) {
            const product = this.orderItem.product;
            this.order = order;
            const newOrderItem   = this.order.findOrderItemByProduct(product);
            if (!newOrderItem) {
                this.orderItem = this.helperService.setupOrderItem(this.order, product);
            } else {
                this.orderItem  = newOrderItem;
            }
        }
    }

    get isItemCut():boolean {
        const product = this.orderItem.product;
        return product?.item_cut ? product.item_cut : false;
    }
}

