import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';
import {AppDate} from '../models/date.model';
import { DATE_FORMAT } from '../constants/date-format';

export const US_LONG_FORMAT = DATE_FORMAT.US_LONG_FORMAT;
export const US_SHORT_FORMAT = DATE_FORMAT.US_SHORT_FORMAT;


@Pipe({
    name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {
    constructor() {
    }

    transform(key: any, args?): string {
        let momentDate;
        let format = US_LONG_FORMAT;

        if (typeof key === 'string') {
            momentDate = moment(new Date(key));
        } else if (typeof key === 'number') {
            momentDate = moment(key);
        } else if (key instanceof Date) {
            momentDate = moment(key)
        } else if (key instanceof AppDate) {
            momentDate = key.date;

            if (args && args.indexOf(' formatAsLocal') > -1) {
                momentDate.utc();
                args = args.replace(' formatAsLocal', '');
            }
        } else if (moment.isMoment(key)) {
            momentDate = key;
        }

        if (args && args === 'us-long') {
            format = US_LONG_FORMAT;
        } else if (args && args === 'us-short') {
            format = US_SHORT_FORMAT;
        } else if (args) {
            format = args;
        }
        return (momentDate && momentDate.isValid()) ? momentDate.format(format ? format : "DD-MMM-YYYY") : '';
    }
}
