<div class="modal-md address">
    <div class="modal-header state">
        <h1 class="modal-title text-truncate full-width text-align-center" id="modal-label">EDIT CONTACT DETAILS</h1>
        <button type="button"  class="btn btn-icon close" (click)="close()" aria-label="Close"><span class="fal fa-times" aria-hidden="true"></span></button>
    </div>
    <div class="modal-body">
        <div class="contact-details--wrapper">
            <p *ngIf="isCorporate" class="font-5 secondary">Any changes to the contact(s) will be saved for this order and will not be saved to the Address Book.</p>

            <h2>Contact Details</h2>

            <app-edit-contact-details [address]="address" (onChange)="handleOnContactDetailsChange($event)"></app-edit-contact-details>

            <div class="d-flex align-items-center" *ngIf="!isCorporate">
                <label class="custom-control custom-checkbox mb-0">
                    <input type="checkbox" class="custom-control-input"
                           [(ngModel)]="saveChangesInAddress">
                    <span class="custom-control-indicator"></span>
                </label>
                <span class="custom-control-description">Save changes against address?</span>
            </div>


            <div class="d-flex justify-content-center">
                <button (click)="save()" class="btn btn-primary" [ngClass]="{'disabled' : !contactDetailsFormValid}" [disabled]="inProgress">
                    <i *ngIf="inProgress" class="fa fa-sync rotate"></i>&nbsp;
                    Save Changes
                </button>
            </div>
        </div>
    </div>
</div>
