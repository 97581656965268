<div class="modal-md address">
    <div class="modal-header state">
        <h1 class="modal-title text-truncate full-width text-align-center" id="modal-label">{{title}}</h1>
        <button type="button"  class="btn btn-icon close" (click)="close()" aria-label="Close"><span class="fal fa-times" aria-hidden="true"></span></button>
    </div>
    <div class="modal-body">
        <ng-container *ngTemplateOutlet="isExistedAddress ? existedAddress : (hasSuggestions?  suggestions : editTemplate)"></ng-container>
    </div>
</div>


<ng-template #editTemplate>
    <form [formGroup]="addressForm" (ngSubmit)="onSaveAddress()" class="form">
        <div class="form-group" [ngClass]="validationClassName(label)">
            <label class="form-control-label font-weight-bold" for="label">Company Name / Address line 1 <span class="font-5 font-weight-normal">(Enter Company Name or Address line 1)</span></label>
            <input class="form-control" id="label" maxlength="255" formControlName="label" placeholder="Enter the Label for your Entry">
            <small class="text-danger" *ngIf="addressForm.get('label').hasError('required')">Company Name / Address line 1 is required</small>
            <small class="text-danger" *ngIf="addressForm.get('label').hasError('maxLengthTrimmed')">Company Name / Address line 1 value exceeds character limit of 250.</small>
        </div>
        <div class="form-group" [ngClass]="validationClassName(street_1)">
            <label class="form-control-label font-weight-bold" for="street_1">Street Address</label>
            <input class="form-control" id="street_1" maxlength="255" formControlName="street_1" trim placeholder="Enter Street Number, P.O. Box">
            <small class="text-danger" *ngIf="addressForm.get('street_1').hasError('required')">A Street Address is required</small>
            <small class="text-danger" *ngIf="addressForm.get('street_1').hasError('maxLengthTrimmed')" >Street Address value exceeds character limit of 250.</small>
        </div>
        <div class="form-group">
            <label class="form-control-label font-weight-bold" for="street_2">Suite/Unit <span class="font-weight-normal font-5">(Optional)</span></label>
            <input class="form-control" id="street_2" maxlength="255" formControlName="street_2" trim placeholder="Enter Apartment, Suite, Unit, Building, Floor, etc">
            <small class="text-danger" *ngIf="addressForm.get('street_2').hasError('maxLengthTrimmed')">Suite/Unit value exceeds character limit of 250.</small>
        </div>
        <div class="form-row">
            <div class="col">
                <div class="form-group" [ngClass]="validationClassName(city)">
                    <label class="form-control-label font-weight-bold" for="city">City</label>
                    <input class="form-control" id="city" maxlength="255" formControlName="city" trim placeholder="Enter City">
                    <small class="text-danger" *ngIf="addressForm.get('city').hasError('required')">A City is required.</small>
                    <small class="text-danger" *ngIf="addressForm.get('city').hasError('maxLengthTrimmed')">City value exceeds character limit of 250.</small>
                </div>
            </div>
            <div class="col">
                <div class="form-group"
                     [ngClass]="{'has-danger' : !isStateFieldValid && stateFieldTouch}">
                    <label class="form-control-label font-weight-bold" for="state_list">State / Province / Region</label>
                    <ng-select
                            *ngIf="states.length"
                            class="custom-select__dropdown"
                            [items]="states"
                            formControlName="state_list"
                            bindLabel="label"
                            bindValue="abbr"
                            placeholder="Enter State, Province, or Region"
                            [searchable]="false"
                            [clearable]="false"
                            (change)="selectState($event)"
                            dropdownPosition="top"
                            id="state_list">
                    </ng-select>
                    <input type="text" formControlName="state" class="form-control"  *ngIf="!states.length" placeholder="Enter State, Province or Region" maxlength="255">
                    <small class="text-danger" *ngIf="stateFieldTouch && !isStateFieldValid">A State is required</small>
                    <small class="text-danger" *ngIf="addressForm.get('state').hasError('maxLengthTrimmed')">State / Province / Region value exceeds character limit of 250.</small>

                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="col">
                <div class="form-group" [ngClass]="validationClassName(zip_code)">
                    <label class="form-control-label font-weight-bold" for="zip_code">Zip / Postal Code</label>
                    <input class="form-control" id="zip_code" maxlength="255" formControlName="zip_code"  placeholder="Enter ZIP / Postal Code">
                    <small class="text-danger" *ngIf="zip_code.errors?.required && (zip_code.touched || zip_code.dirty)">A Zip Code is required</small>
                    <small class="text-danger" *ngIf="zip_code.errors?.pattern && (zip_code.touched || zip_code.dirty)">A Zip Code must be in XXXXX or XXXXX-XXXX formats</small>
                    <small class="text-danger" *ngIf="addressForm.get('zip_code').hasError('maxLengthTrimmed')">Zip / Postal Code value exceeds character limit of 250.</small>
                </div>
            </div>
            <div class="col">
                <div class="form-group">
                    <label class="form-contol-label font-weight-bold">Country</label>
                    <ng-select
                            class="custom-select__dropdown"
                            formControlName="country"
                            [items]="countries"
                            bindLabel="label"
                            bindValue="isoCode"
                            placeholder="Choose Option"
                            dropdownPosition="top"
                            [searchable]="false"
                            [clearable]="false"
                            (change)="selectCountry($event)">
                    </ng-select>
                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="col">
                <div class="form-group" [ngClass]="validationClassName(phone)">
                    <label class="form-control-label font-weight-bold" for="phone">Phone Number</label>
                    <input type="tel" class="form-control" id="phone" maxlength="30" formControlName="phone"  placeholder="Enter Phone Number" (keypress)="onIsNumberAndSymbolKey($event)">
                    <small class="text-danger" *ngIf="addressForm.get('phone').hasError('maxLengthTrimmed')">Phone Number value exceeds character limit of 25.</small>

                </div>
            </div>
            <div class="col">
                <div class="form-group">
                    <label class="form-control-label font-weight-bold" for="client_address_id">Client Address ID</label>
                    <input type="text" class="form-control" id="client_address_id" maxlength="50" formControlName="client_address_id"  placeholder="Enter Client Address ID">
                </div>
            </div>
        </div>
        <div class="form-group mb-4">
            <label class="form-control-label font-weight-bold" for="delivery_instructions">Delivery Instructions</label>
            <textarea class="form-control" id="delivery_instructions" maxlength="500" placeholder="Enter Delivery Instructions" formControlName="delivery_instructions" rows="3"></textarea>
        </div>

        <h4 class="text-transformation-none text-underline">Contact Details</h4>

        <app-edit-contact-details [address]="selectedAddress" (onChange)="handleOnContactDetailsChange($event)"></app-edit-contact-details>

        <div class="d-flex justify-content-center">
            <button type="submit" class="btn btn-primary" [ngClass]="{'disabled' : !addressForm.valid || !contactDetailsFormValid}" [disabled]="inProgress">
                <i *ngIf="inProgress" class="fa fa-sync rotate"></i>&nbsp;
                Save Address
            </button>
        </div>
    </form>
</ng-template>


<ng-template #suggestions>
    <p>To ensure delivery accuracy, we suggest the below addresses. Please select an address or re-edit your address.</p>

    <h3 class="text-align-center color-green mt-3">Valid Addresses</h3>
    <div class="d-flex flex-column align-items-center addresses--container">
        <div *ngFor="let address of suggestedAddresses" class="d-flex align-items-center mb-3" style="width: 240px">
            <div class="address--selector">
                <input class="checkbox-dark-gray tax-exempt" type="checkbox"  id="tax-exempt"
                       [checked]="selectedSuggestionUUID === address.uuid"
                       (change)="onSelectedSuggestion(address)" >
            </div>
            <div>
                {{address.street_1}}<br/>
                {{address.city}}, {{address.state}}, {{address.zip_code}} <br/>
                {{getCountryByCode(address.country_code).label}}
            </div>
        </div>
    </div>

    <h3 class="text-align-center mt-3 color--primary-grey">Entered Address</h3>
    <div class="d-flex flex-column align-items-center">
        <div class="d-flex align-items-center" style="width: 240px">
            <div class="address--selector" style="min-width: 3rem"></div>
            <div>
                {{selectedAddress.street_1}}<br/>
                {{selectedAddress.city}}, {{selectedAddress.state}}, {{selectedAddress.zip_code}} <br/>
                {{getCountryByCode(selectedAddress.country_code).label}}
            </div>
        </div>
    </div>

    <div class="d-flex align-items-center justify-content-center mt-3">
        <button class="btn btn-outline-primary mr-3" (click)="onBackToEdit()">
            Edit Address
        </button>
        <button class="btn btn-primary"  [disabled]="!selectedSuggestionUUID || inProgress" (click)="onSavedValidated()">
            <i *ngIf="inProgress" class="fa fa-sync rotate"></i>&nbsp;
            Save Address
        </button>
    </div>
</ng-template>

<ng-template #existedAddress>
    <p class="text-align-center">{{existedAddressWarning}}</p>

    <h3 class="text-align-center color-green mt-3">Existing Addresses</h3>
    <div class="d-flex flex-column align-items-center addresses--container">
        <div *ngFor="let address of suggestedAddresses" class="d-flex align-items-center mb-3" style="width: 240px">
            <div class="address--selector" style="min-width: 3rem">
            </div>
            <div>
                <ng-container *ngIf="address.iShippingLocation">{{address.label}}<br/></ng-container>
                {{address.street_1}}<br/>
                {{address.city}}, {{address.state}}, {{address.zip_code}} <br/>
                {{getCountryByCode(address.country_code).label}}
            </div>
        </div>
    </div>

    <h3 class="text-align-center mt-3 color--primary-grey">Entered Address</h3>
    <div class="d-flex flex-column align-items-center">
        <div class="d-flex align-items-center" style="width: 240px">
            <div class="address--selector" style="min-width: 3rem"></div>
            <div>
                {{selectedAddress.street_1}}<br/>
                {{selectedAddress.city}}, {{selectedAddress.state}}, {{selectedAddress.zip_code}} <br/>
                {{getCountryByCode(selectedAddress.country_code).label}}
            </div>
        </div>
    </div>

    <div class="d-flex align-items-center justify-content-center mt-3">
        <button class="btn btn-outline-primary mr-3" (click)="onBackToEdit()">
            Edit
        </button>
        <button class="btn btn-primary" (click)="close()">
            Close
        </button>
    </div>
</ng-template>
