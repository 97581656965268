export const DATE_FORMAT = {
    // Long format for US (Month-Day-Year)
    US_LONG_FORMAT: 'MMM-DD-YYYY',
    // Short format for US (Month-Day)
    US_SHORT_FORMAT: 'MM-DD-YYYY',
    LONG_FORMAT: 'DD-MMM-YYYY',
    SHORT_FORMAT: 'DD-MM-YYYY',
    SHORT_DATE_MONTH:"DD/MMM",
    LONG_DAY_MONTH_YEAR_FORMAT: "DD MMMM YYYY",
    SHORT_DAY_MONTH_FORMAT: "DD MMMM",
    MONTHS_F_NAME_ARRAY: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    MONTHS_S_NAME_ARRAY: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
}
