<ng-container *ngTemplateOutlet="(readonly || !canOrder)? readOnlyTemplate : mobileDevice ? mobileTemplate : desktopTemplate ">

</ng-container>

<ng-template #desktopTemplate>
    <div [ngClass]="{'line-separator--light-grey': isLastShipment}" class="pb-3">
        <div class="purchase-options__address delivery--input" #divContainer
             *ngIf="delivery && product"
             [ngClass]="{'decision-point' : order.isDecisionPoint, 'list-item': viewType === 'list-item'}">

            <div class="purchase-options__address--input pt-0"
                 [ngClass]="qtyContainerCSS"
                 *ngIf="!product.hasVariations">

                <div class="label color--primary-grey" *ngIf="_showInputLabel">Qty Per Address</div>
                <div class="full-width">

                    <input *ngIf="!product.isCustomizable"
                           class="m-0"
                           (change)="onQuantityChanged($event)"
                           (blur)="onQuantityBlur()"
                           min="0"  digitOnly type="number"
                           [value]="delivery.quantity"
                           [disabled]="!canPurchase || delivery.is_uneligible"

                    />

                    <ng-container *ngIf="!delivery.is_uneligible">
                        <div  *ngIf="product.isCustomizable" class="customizations-tooltip mb-2">
                    <span class="input-like m-0"
                          (click)="addCustomization()">{{delivery.quantity}}</span>

                            <customization-info [customizations]="delivery.customizations"></customization-info>

                        </div>

                        <div *ngIf="product.isCustomizable">
                            <button  (click)="addCustomization()" [disabled]="!canCustomize" class="btn btn-outline-primary mr-2">Customize</button>
                            <i *ngIf="!canCustomize && order.isDecisionPoint" class="fal fa-exclamation-triangle yellow"
                               title="This product can be customized but Decision Point Order does not support it. Please use other order."></i>
                        </div>


                    </ng-container>

                </div>
            </div>

            <div class="purchase-options__address--select"
                 [ngClass]="_shipToAddressContainerCss">
                <div class="label color--primary-grey" *ngIf="_showInputLabel">Ship-To Address</div>
                <app-ship-to-address [isInline]="true" [order]="order"
                                     [delivery]="delivery"
                                     [orderItem]="orderItem"
                                     (addressSelected)="updateAddressData($event)"
                                     [disabled]="!canPurchase" >
                </app-ship-to-address>



            </div>

            <div class="delivery--shipping-method" [ngClass]="_shippingMethodContainerCSS">
                <div  class="label color--primary-grey" *ngIf="_showInputLabel">{{ mobile ? 'Shipping' : 'Shipping Method'}}</div>
                <ng-container *ngIf="isSingleShippingMethod && !delivery.is_uneligible">
                    <div class='shipping-method--wrapper'>
                        <span>{{findMethodLabel(product, delivery)}}</span>
                    </div>
                </ng-container>
                <ng-container *ngIf="!isSingleShippingMethod && !delivery.is_uneligible">
                    <ng-select
                            class="ng-select--custom"
                            style="max-width: 150px"
                            [items]="shippingMethods"
                            bindValue="method"
                            [searchable]="false"
                            (change)="updateShippingMethod($event)"
                            [clearable]="false"
                            [(ngModel)]="delivery.method"
                            [disabled]="!canPurchase"
                            placeholder="Select Method"
                            dropdownPosition="bottom">
                        <ng-template ng-label-tmp let-item="item">
                            <span class="font-5 grey">{{ item.method_label }}</span>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item">
                            <span class="font-5">{{ item.method_label }}</span>
                        </ng-template>
                    </ng-select>
                </ng-container>
                <ng-container *ngIf="delivery.is_uneligible">
                    <span style="line-height: 3">Not Eligible &nbsp;<button class="btn btn-icon" (click)="showDPFaqDialog()"><i class="fas fa-exclamation-circle"></i></button></span>
                </ng-container>
            </div>

            <div class="delivery--wbs pt-0" *ngIf="canShowInternalOrder"
                 [ngClass]="_internalOrderContainerCss">
                <div class="label color--primary-grey" *ngIf="_showInputLabel">{{internalOrderLabel}}</div>
                <div class="full-width">
                    <app-internal-order-code [order]="order"  [orderItem]="orderItem" [delivery]="delivery" [disabled]="!canPurchase">
                    </app-internal-order-code>
                </div>
            </div>
            <div *ngIf="showTotalInline" class="col col-2 flex-line" [ngClass]="{'list-item': viewType === 'list-item'}">
                <div *ngIf="isLastShipment">
                    <strong class="price"><app-currency-price [price]="totals" [currency]="product.currency"  ></app-currency-price></strong>
                    <span>SubTotal</span>
                </div>
            </div>

        </div>
        <div *ngIf="canRemoveAddress" class="col-12">
            <strong class="purchase-options__address-remove"  (click)="removeAddress()">Remove</strong>
        </div>

    </div>


</ng-template>


<ng-template #mobileTemplate>
    <div>
        <div class="purchase-options__address delivery--input"
             *ngIf="delivery && product"
             [ngClass]="{'decision-point' : order.isDecisionPoint, 'list-item': viewType === 'list-item', 'line-separator--light-grey': isLastShipment}">


            <div class="purchase-options__address--input shipment--input"
                 [ngClass]="{'list-item': viewType === 'list-item'}"
                 *ngIf="!product.hasVariations">

                <div *ngIf="!product.hasVariations" class="shipment--input">
                    <div class="label color--primary-grey">Qty Per Address</div>
                </div>

                <div class="d-block">

                    <input *ngIf="!product.isCustomizable"
                           class="mb-0"
                           (change)="onQuantityChanged($event)"
                           (blur)="onQuantityBlur()"
                           min="0"  digitOnly type="number"
                           [value]="delivery.quantity"
                           [disabled]="!canPurchase || delivery.is_uneligible"

                    />

                    <ng-container *ngIf="!delivery.is_uneligible">
                        <div  *ngIf="product.isCustomizable" class="customizations-tooltip mb-2">
                    <span class="input-like m-0"
                          (click)="addCustomization()">{{delivery.quantity}}</span>

                            <customization-info [customizations]="delivery.customizations"></customization-info>

                        </div>

                        <div *ngIf="product.isCustomizable">
                            <button  (click)="addCustomization()" [disabled]="!canCustomize" class="btn btn-outline-primary mr-2">Customize</button>
                            <i *ngIf="!canCustomize && order.isDecisionPoint" class="fal fa-exclamation-triangle yellow"
                               title="This product can be customized but Decision Point Order does not support it. Please use other order."></i>
                        </div>


                    </ng-container>

                </div>
            </div>

            <div class="purchase-options__address--select shipment--address"
                 [class.short]="!product.hasVariations"
                 [ngClass]="{'list-item': viewType === 'list-item'}">

                <div class="shipment--address" >
                    <div class="label color--primary-grey">Ship-To Address</div>
                </div>

                <app-ship-to-address [isInline]="true" [order]="order"
                                     [delivery]="delivery"
                                     [orderItem]="orderItem"
                                     className="full-width"
                                     (addressSelected)="updateAddressData($event)"
                                     [disabled]="!canPurchase" >
                </app-ship-to-address>



            </div>

            <div class="shipment--method" style='margin-right: 10px'>
                <div class="shipment--method">
                    <div  class="label color--primary-grey">{{ 'Shipping'}}</div>
                </div>

                <ng-container *ngIf="isSingleShippingMethod && !delivery.is_uneligible">
                    <div class='shipping-method--wrapper'>
                        <span>{{findMethodLabel(product, delivery)}}</span>
                    </div>
                </ng-container>
                <ng-container *ngIf="!isSingleShippingMethod && !delivery.is_uneligible">
                    <ng-select
                            class="ng-select--custom"
                            [items]="shippingMethods"
                            bindValue="method"
                            [searchable]="false"
                            (change)="updateShippingMethod($event)"
                            [clearable]="false"
                            [(ngModel)]="delivery.method"
                            [disabled]="!canPurchase"
                            dropdownPosition="bottom">
                        <ng-template ng-label-tmp let-item="item">
                            <span class="font-5 grey">{{ item.method_label }}</span>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item">
                            <span class="font-5">{{ item.method_label }}</span>
                        </ng-template>
                    </ng-select>
                </ng-container>
                <ng-container *ngIf="delivery.is_uneligible">
                    <span style="line-height: 3">Not Eligible &nbsp;<button class="btn btn-icon" (click)="showDPFaqDialog()"><i class="fas fa-exclamation-circle"></i></button></span>
                </ng-container>
            </div>


            <div class="purchase-options__address--select shipment--address" *ngIf="canShowInternalOrder">
                <div class="label color--primary-grey" >{{internalOrderLabel}}</div>
                <div>
                  <app-internal-order-code
                    [order]="order"
                    [orderItem]="orderItem"
                    [disabled]="!canPurchase"
                    [delivery]="delivery"></app-internal-order-code>
                </div>
            </div>

            <div *ngIf="showTotalInline" class="col col-2 flex-line" [ngClass]="{'list-item': viewType === 'list-item'}">
                <div *ngIf="isLastShipment">
                    <strong class="price"><app-currency-price [currency]="product.currency" [price]="totals"></app-currency-price></strong>
                    <span>SubTotal</span>
                </div>
            </div>

        </div>
        <div><strong class="purchase-options__address-remove" *ngIf="canRemoveAddress" (click)="removeAddress()">Remove</strong></div>
    </div>

</ng-template>

<ng-template #readOnlyTemplate>
    <div class="order-item--bottom d-flex mt-3" *ngIf="delivery.quantity > 0">
        <div  [ngClass]="{'col-12': !mobileDevice, 'full-width': mobileDevice}">
            <div>
                <ng-container *ngTemplateOutlet="order.isCustom ? ( showTrackingInfo ? withSapDataAndTracking : withSapDataOnly ) :
                showTrackingInfo ? withTrackingData: withoutTrackingData"></ng-container>
            </div>
        </div>
    </div>

    <div *ngIf="product.hasVariations" class="variation--container">
        <div class="d-flex font-5 font-weight-bold mt-3 py-1 line-up-separator--grey">
            <div class="col-5 px-0">Variations</div>
            <div class="col-3 px-0 pl-3 d-flex justify-content-center">Price Per Unit</div>
            <div class="col-2 px-0 pl-3 d-flex justify-content-center">Quantity</div>
            <div class="col-2 px-0 pl-3 d-flex justify-content-center">Total</div>
        </div>
        <div *ngFor="let sku of orderItem.skus; let i = index" class="d-flex py-1 line-up-separator--grey font-5">
            <div class="col-5 px-0">
                <div>{{getVariationLabel(sku)}}</div>
                <ng-container *ngIf="sku.urn || product.skus[i].urn as urn">URN: {{urn}}</ng-container>
            </div>
            <div class="col-3 px-0 pl-3 d-flex justify-content-center">
                <span class="price font-weight-normal"><app-currency-price [price]="getVariationPrice(sku)" [currency]="product.currency"></app-currency-price></span>
            </div>
            <div class="col-2 px-0 pl-3 d-flex justify-content-center">
                {{sku.quantity}}
            </div>
            <div class="col-2 px-0 pl-3 d-flex justify-content-center">
                <span class="price font-weight-normal"><app-currency-price [price]="getVariationTotal(sku)" [currency]="product.currency"></app-currency-price></span>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #withTrackingData>
    <ng-container *ngTemplateOutlet="mobileDevice? mobileWithTrackingData : desktopWithTrackingData"></ng-container>
</ng-template>

<ng-template #mobileWithTrackingData>

    <div class="order-item--shipment d-flex">
        <div class="order-item--qty col-3">
            <div class="label color--primary-grey">Quantity</div>
            <div class="d-flex" [ngClass]="{'strikethrough' : isItemCut}">
                <strong class="mr-2">{{ paidQuantity ? paidQuantity :  delivery.quantity }}</strong>
                <customization-info [customizations]="delivery.customizations"></customization-info>
            </div>
        </div>
        <div class="order-item--address col-6">
            <div class="label color--primary-grey">Ship-To Address</div>
            <div><strong>{{ getShipToLabel(delivery) }}</strong>&nbsp;</div>
            <span>{{ delivery.street_1 }}</span>
            <span>{{ delivery.getCSZ() }}</span>

        </div>

        <div class="order-item--shipping col-3" [ngClass]="{'delivered': isDelivered(status), 'tracking--loader': _refreshingTrackingStatus}">
            <div class="label color--primary-grey">Status</div>
            <div>{{status | translate}}</div>
        </div>

    </div>
    <div class="d-flex mt-2 font-5" *ngIf="canShowInternalOrder">
        <div class="label color--primary-grey col-3">{{internalOrderLabel}}</div>
        <div class="order-item--shipping tracking--link col-9">
            <strong>{{delivery.internalOrder || order.internalOrder}}</strong>
        </div>
    </div>

    <div class="d-flex mt-2 font-5">
        <div class="label color--primary-grey col-3">Tracking</div>
        <div class="order-item--shipping tracking--link col-9" [ngClass]="{'tracking--loader': _refreshingTrackingStatus}">
            <app-delivery-tracking [vendor]="orderItem.vendor"
                                   [orderState]="order.state"
                                   [delivery]="delivery"></app-delivery-tracking>
        </div>
    </div>
</ng-template>

<ng-template #desktopWithTrackingData>
    <div class="order-item--shipment d-flex font-6 uppercase mb-2">
    </div>

    <div class="order-item--shipment d-flex">
        <div class="order-item--qty col-sm-2">
            <div class="label color--primary-grey" *ngIf="isFirstShipment">Quantity</div>
            <div class="d-flex flex-column align-items-center" [ngClass]="{'strikethrough' : isItemCut}">
                <strong>{{ paidQuantity ? paidQuantity :  delivery.quantity }}</strong>
                <customization-info [customizations]="delivery.customizations"></customization-info>
            </div>
        </div>
        <div class="order-item--address col-sm-4">
            <div class="label color--primary-grey" *ngIf="isFirstShipment">Ship-To Address</div>
            <div><strong>{{ getShipToLabel(delivery) }}</strong>&nbsp;</div>
            <span>{{ delivery.street_1 }}</span>
            <span>{{ delivery.getCSZ() }}</span>

        </div>

        <div class="order-item--shipping col-sm-2" [ngClass]="{'delivered': isDelivered(status), 'tracking--loader': _refreshingTrackingStatus}">
            <div class="label color--primary-grey" *ngIf="isFirstShipment">Status</div>
            <div>{{isItemCut ? 'Cut' : status | translate}}</div>
        </div>

        <div class="order-item--shipping col-sm-2" *ngIf="canShowInternalOrder">
            <div class="label color--primary-grey" *ngIf="isFirstShipment">{{internalOrderLabel}}</div>
            <div><strong>{{delivery.internalOrder || order.internalOrder}}</strong></div>
        </div>

        <div class="order-item--shipping col-sm-4 tracking--link" [ngClass]="{'tracking--loader': _refreshingTrackingStatus}">
            <div class="label color--primary-grey" *ngIf="isFirstShipment">Tracking&nbsp;
                <button class="btn btn-icon"  title="Refresh tracking status" (click)="refreshStatus.emit()">
                    <i class="fal fa-sync" [ngClass]="{'rotate' : _refreshingTrackingStatus}" ></i>
                </button></div>
            <app-delivery-tracking [vendor]="orderItem.vendor"
                                   [orderState]="order.state"
                                   [delivery]="delivery"></app-delivery-tracking>
        </div>
    </div>

</ng-template>

<ng-template #withoutTrackingData>
    <ng-container *ngTemplateOutlet="mobileDevice ? mobileWithoutTrackingData: desktopWithoutTrackingData"></ng-container>
</ng-template>

<ng-template #mobileWithoutTrackingData>
    <div>
        <div class="d-flex order-item--shipment font-5 uppercase mb-2" >
            <div class="col-2 px-0 text-align-center">

            </div>
            <div class="col-6 px-0 pl-3">

            </div>
            <div class="col-4 px-0 pl-3">

            </div>

        </div>

        <div class="order-item--shipment d-flex">
            <div class="order-item--qty col-2 px-0">
                <div class=" color--primary-grey" *ngIf="isFirstShipment ">Quantity</div>
                <div class="d-flex flex-column align-items-center">
                    <strong>{{ paidQuantity ? paidQuantity :  delivery.quantity }}</strong>
                    <customization-info [customizations]="delivery.customizations"></customization-info>
                </div>
            </div>
            <div class="order-item--address col-5 px-0 pl-3">
                <div class="label color--primary-grey" *ngIf="isFirstShipment ">Ship-To Address</div>
                <div><strong>{{ getShipToLabel(delivery) }}</strong>&nbsp;</div>
                <span>{{ delivery.street_1 }}</span>
                <span>{{ delivery.getCSZ() }}</span>

            </div>
            <div class="order-item--backordered col-3  px-0 pl-3" *ngIf="!completed">
                <div class="label color--primary-grey" *ngIf="isFirstShipment ">Shipping</div>
                <div>
                    <strong>{{ findMethodLabel(product, delivery) }}</strong>
                </div>
            </div>
            <div class="order-item--shipping col-3  px-0 pl-3" *ngIf="completed">
                <div class="label color--primary-grey" *ngIf="isFirstShipment">Shipping</div>
                <div>
                    <strong>{{ findMethodLabel(product, delivery) }}</strong>
                </div>
                <span>Status: {{ orderItem.state | translate }}</span>
                <div *ngIf="delivery.tracking_number">
                    <app-tracking-data [trackingData]="delivery.tracking_number"></app-tracking-data>
                </div>
            </div>
            <div class="order-item--shipping col-2  px-0 pl-3" *ngIf="canShowInternalOrder">
                <div class="label color--primary-grey" *ngIf="isFirstShipment ">{{internalOrderLabel}}</div>
                <strong >{{ delivery.internalOrder || order.internalOrder }}</strong>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #desktopWithoutTrackingData>
    <div>
        <div class="order-item--shipment d-flex">
            <div class="order-item--qty col-2">
                <div class="label color--primary-grey" *ngIf="isFirstShipment ">Quantity</div>
                <div class="d-flex flex-column align-items-center">
                    <strong>{{ paidQuantity ? paidQuantity :  delivery.quantity }}</strong>
                    <customization-info [customizations]="delivery.customizations"></customization-info>
                </div>
            </div>
            <div class="order-item--address col-6">
                <div class="label color--primary-grey" *ngIf="isFirstShipment ">Ship-To Address</div>
                <div class="d-flex align-items-center">
                    <strong>{{ getShipToLabel(delivery) }}</strong>&nbsp;
                    <button class="btn btn-icon ml-2" (click)="editContactDetails(delivery)"><i class="fal fa-edit"></i></button>
                </div>
                <span>{{ delivery.street_1 }}</span>
                <span>{{ delivery.getCSZ() }}</span>

            </div>
            <div class="order-item--backordered col-2" *ngIf="!completed">
                <div class="label color--primary-grey" *ngIf="isFirstShipment">Shipping</div>
                <div>
                    <strong>{{ findMethodLabel(product, delivery) }}</strong>
                </div>
            </div>
            <div class="order-item--shipping col-2" *ngIf="completed">
                <div class="label color--primary-grey" *ngIf="isFirstShipment">Shipping</div>
                <div>
                    <strong>{{ findMethodLabel(product, delivery) }}</strong>
                </div>
                <span>Status: {{ orderItem.state | translate }}</span>
                <div *ngIf="delivery.tracking_number">
                    <app-tracking-data [trackingData]="delivery.tracking_number"></app-tracking-data>
                </div>
            </div>
            <div *ngIf="canShowInternalOrder" class="order-item--shipping col-2">
                <div class="label color--primary-grey"  *ngIf="isFirstShipment">{{internalOrderLabel}}</div>
                <div>
                    <app-internal-order-code
                            [order]="order"
                            [orderItem]="orderItem"
                            [disabled]="!canPurchase"
                            [delivery]="delivery"
                            [readonly]="!showEditControls"
                            [showWithModalDialog]="true"
                            [shouldUpdateOrder]="true">
                    </app-internal-order-code>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #withSapDataOnly>
    <ng-container *ngTemplateOutlet="mobileDevice ?  mobileWithSapDataOnly : desktopWithSapDataOnly"></ng-container>
</ng-template>

<ng-template #desktopWithSapDataOnly>
    <div>
        <div class="order-item--shipment d-flex">
            <div class="order-item--qty col-2">
                <div class="label color--primary-grey" *ngIf="isFirstShipment ">Quantity</div>
                <div class="d-flex flex-column align-items-center">
                    <strong>{{ paidQuantity ? paidQuantity :  delivery.quantity }}</strong>
                    <customization-info [customizations]="delivery.customizations"></customization-info>
                </div>
            </div>
            <div class="order-item--address col-5">
                <div class="label color--primary-grey" *ngIf="isFirstShipment ">Ship-To Address</div>
                <div class="d-flex align-items-center">
                    <strong>{{ getShipToLabel(delivery) }}</strong>&nbsp;
                    <button class="btn btn-icon ml-2"><i class="fal fa-edit" (click)="editContactDetails(delivery)"></i></button>
                </div>
                <span>{{ delivery.street_1 }}</span>
                <span>{{ delivery.getCSZ() }}</span>

            </div>
            <div class="order-item--backordered col-3" *ngIf="!completed">
                <div class="label color--primary-grey"  *ngIf="isFirstShipment ">SAP</div>
                <div>
                    {{getSapInfo(delivery)}}
                    <button *ngIf="canEditDeliveryTo"
                            (click)="editSAP(delivery)"
                            [disabled]="updatingDeliveryId === delivery.id"
                            title="Edit SAP"
                            class="btn btn-icon ml-2">
                        <i class="fal fa-sync rotate" *ngIf="updatingDeliveryId === delivery.id"></i>
                        <i class="fal fa-edit" *ngIf="updatingDeliveryId !== delivery.id"></i>
                    </button>

                </div>
            </div>
            <div class="order-item--shipping col-3" *ngIf="completed">
                <div class="label color--primary-grey"  *ngIf="isFirstShipment ">SAP</div>
                <div>{{getSapInfo(delivery)}}</div>
                <span>Status: {{ orderItem.state | translate }}</span>
                <div *ngIf="delivery.tracking_number">
                    <app-tracking-data [trackingData]="delivery.tracking_number"></app-tracking-data>
                </div>
            </div>
            <div *ngIf="canShowInternalOrder" class="order-item--shipping col-2">
                <div class="label color--primary-grey"  *ngIf="isFirstShipment">{{internalOrderLabel}}</div>
                <div>
                    <app-internal-order-code
                            [order]="order"
                            [orderItem]="orderItem"
                            [readonly]="!showEditControls"
                            [showWithModalDialog]="true"
                            [disabled]="!canPurchase"
                            [delivery]="delivery"></app-internal-order-code>
                </div>
            </div>

        </div>
    </div>
</ng-template>

<ng-template #mobileWithSapDataOnly>
    <div>
        <div class="order-item--shipment d-flex">
            <div class="order-item--qty col-2 px-0">
                <div class="label color--primary-grey"  *ngIf="isFirstShipment ">Quantity</div>
                <div class="d-flex flex-column align-items-center">
                    <strong>{{ paidQuantity ? paidQuantity :  delivery.quantity }}</strong>
                    <customization-info [customizations]="delivery.customizations"></customization-info>
                </div>
            </div>
            <div class="order-item--address col-10 px-0 pl-3">
                <div class="label color--primary-grey">Ship-To Address</div>
                <div><strong>{{ getShipToLabel(delivery) }}</strong>&nbsp;</div>
                <span>{{ delivery.street_1 }}</span>
                <span>{{ delivery.getCSZ() }}</span>

            </div>
        </div>
        <div class="d-flex mt-2" *ngIf="canShowInternalOrder">
            <div class="label color--primary-grey">{{internalOrderLabel}}</div>&nbsp;
            <div>{{delivery.internalOrder || order.internalOrder}}</div>
        </div>

        <div class="d-flex mt-2">
            <div class="label color--primary-grey">Billing Code: <span>{{getSapInfo(delivery)}}</span>
                <button *ngIf="canEditDeliveryTo && !completed"
                        (click)="editSAP(delivery)"
                        [disabled]="updatingDeliveryId === delivery.id"
                        title="Edit SAP"
                        class="btn btn-icon ml-2">
                    <i class="fal fa-sync rotate" *ngIf="updatingDeliveryId === delivery.id"></i>
                    <i class="fal fa-edit" *ngIf="updatingDeliveryId !== delivery.id"></i>
                </button>
                <ng-container *ngIf="completed">
                    <span class="d-block">Status: {{ orderItem.state | translate }}</span>
                    <div *ngIf="delivery.tracking_number">
                        <app-tracking-data [trackingData]="delivery.tracking_number"></app-tracking-data>
                    </div>
                </ng-container>
            </div>
        </div>

    </div>
</ng-template>

<ng-template #withSapDataAndTracking>
    <ng-container *ngTemplateOutlet="mobileDevice ? withSapDataAndTrackingMobileView : withSapDataAndTrackingDesktopView"></ng-container>
</ng-template>


<ng-template #withSapDataAndTrackingDesktopView>
    <div>
        <div class="order-item--shipment d-flex">
            <div class="order-item--qty col-2">
                <div class="label color--primary-grey" *ngIf="isFirstShipment ">Quantity</div>
                <div class="d-flex flex-column align-items-center">
                    <strong>{{ paidQuantity ? paidQuantity :  delivery.quantity }}</strong>
                    <customization-info [customizations]="delivery.customizations"></customization-info>
                </div>
            </div>
            <div class="order-item--address col-3">
                <div class="label color--primary-grey" *ngIf="isFirstShipment ">Ship-To Address</div>
                <div>
                    <strong>{{ getShipToLabel(delivery) }}</strong>
                </div>
                <span>{{ delivery.street_1 }}</span>
                <span>{{ delivery.getCSZ() }}</span>

            </div>
            <div class="order-item--backordered col-3">
                <div class="label color--primary-grey" *ngIf="isFirstShipment ">SAP</div>
                <div>
                    {{getSapInfo(delivery)}}
                </div>
            </div>

            <div class="order-item--shipping col-sm-2" [ngClass]="{'delivered': isDelivered(status), 'tracking--loader': _refreshingTrackingStatus}">
                <div class="label color--primary-grey" *ngIf="isFirstShipment ">Status</div>
                <div>{{status | translate}}</div>
            </div>

            <div class="order-item--shipping col-sm-2" *ngIf="canShowInternalOrder">
                <div class="label color--primary-grey" *ngIf="isFirstShipment ">{{internalOrderLabel}}</div>
                <div><strong>{{delivery.internalOrder || order.internalOrder}}</strong></div>
            </div>

            <div class="order-item--shipping col-sm-4 tracking--link" [ngClass]="{'tracking--loader': _refreshingTrackingStatus}">
                <div class="label color--primary-grey" *ngIf="isFirstShipment ">Tracking&nbsp;
                    <button class="btn btn-icon"  title="Refresh tracking status" (click)="refreshStatus.emit()">
                        <i class="fal fa-sync" [ngClass]="{'rotate' : _refreshingTrackingStatus}" ></i>
                    </button></div>
                <app-delivery-tracking [vendor]="orderItem.vendor"
                                       [orderState]="order.state"
                                       [delivery]="delivery"></app-delivery-tracking>
            </div>

        </div>
    </div>

</ng-template>

<ng-template #withSapDataAndTrackingMobileView>
    <div>
        <div class="order-item--shipment d-flex">
            <div class="order-item--qty col-3">
                <div class="label color--primary-grey" *ngIf="isFirstShipment ">Quantity</div>
                <div class="d-flex flex-column align-items-center" [ngClass]="{'strikethrough' : isItemCut}">
                    <strong>{{ paidQuantity ? paidQuantity :  delivery.quantity }}</strong>
                    <customization-info [customizations]="delivery.customizations"></customization-info>
                </div>
            </div>
            <div class="order-item--address col-6">
                <div class="label color--primary-grey">Ship-To Address</div>
                <div>
                    <strong>{{ getShipToLabel(delivery) }}</strong>
                </div>
                <span>{{ delivery.street_1 }}</span>
                <span>{{ delivery.getCSZ() }}</span>

            </div>

            <div class="order-item--shipping col-3" [ngClass]="{'delivered': isDelivered(status), 'tracking--loader': _refreshingTrackingStatus}">
                <div class="label color--primary-grey" *ngIf="isFirstShipment ">Status</div>
                <div>{{status | translate}}</div>
            </div>


        </div>

        <div class="font-5 mt-2 pb-2" [class.line-separator--light-grey] = "!isLastShipment">
            <div class="d-flex">
                <div class="col-3 label color--primary-grey">Billing Code:</div>
                <div class="col-9">
                    {{getSapInfo(delivery)}}
                </div>
            </div>
            <div class="d-flex" *ngIf="canShowInternalOrder">
                <div class="col-3 label color--primary-grey">{{internalOrderLabel}}</div>
                <div class="col-9">
                    {{delivery.internalOrder || order.internalOrder}}
                </div>
            </div>

            <div class="d-flex">
                <div class="col-3">
                    <div class="label color--primary-grey">Tracking:</div>
                </div>

                <div class="col-9 order-item--shipping tracking--link" [ngClass]="{'tracking--loader': _refreshingTrackingStatus}">
                    <app-delivery-tracking [vendor]="orderItem.vendor"
                                           [orderState]="order.state"
                                           [delivery]="delivery"></app-delivery-tracking>
                </div>
            </div>

        </div>
    </div>

</ng-template>
