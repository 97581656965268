import { Component, Input, OnInit, AfterViewInit, ViewEncapsulation } from '@angular/core';
import {Router} from '@angular/router';
import { OrderWindow } from '../../../../models/order-window.model';
import { OrderService } from '../../../../services/order.service';
import { DATE_FORMAT } from 'app/constants/date-format';


@Component({
    selector: 'app-header-callout',
    templateUrl: './header-callout.component.html',
    styleUrls: ['./header-callout.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class HeaderCalloutComponent implements OnInit, AfterViewInit {
    public defaultDateMonthYearFormat = DATE_FORMAT.SHORT_DAY_MONTH_FORMAT;

    @Input() statistics: any = {};

    @Input() orderWindow: OrderWindow;

    constructor(
        private orderService: OrderService,
        private router: Router
    ) {}

    ngOnInit() {}
    ngAfterViewInit() {}

    get formattedDates( ): string {
        if (!this.orderWindow) {
            return '';
        }
        return `${this.orderWindow.start_at.format(this.defaultDateMonthYearFormat)} - ${this.orderWindow.end_at.format(this.defaultDateMonthYearFormat)}`;
    }

    selectActiveWindow() {
        if (this.router.url === '/') {
            // for home page - switch order window - otherwise do redirect to buying windows programs
            this.orderService.switchActiveOrderWindow( );
        } else {
            const activeBuyingWindow = this.orderService.findLastActiveOrder();
            if (activeBuyingWindow) {
                this.router.navigate(['/buying-window', activeBuyingWindow.id, 'programs']);
            }
        }
    }
}
