
import { BaseModel } from './base.model';
import { AppDate } from './date.model';
import { Attribute } from './attribute.model';
import { Product } from './product.model';
import { Address } from './address.model';
import { OrderItemSku } from './order-item-sku.model';
import { OrderItemDelivery } from './order-item-delivery.model';
import {generateUUID, isEmptyArray} from '../shared/utils';
import { SKU } from './sku.model';
import {CUSTOM_TYPE, ECOMMERCE_TYPE, PREORDER_TYPE} from '../constants/order-types';
import {COMPLETE_STATE, IN_PROGRESS_STATE, QUOTE_STATE, VOID_STATE} from '../constants/order-states';
import {Budget} from './budget.model';
import {Customization} from '../interfaces/customization';

const MAX_DELIVERY_QUANTITY = 999999;

export class OrderItem extends BaseModel {
    public id: number;
    public order_id: number;
    public order_type: string;
    public entity_id: number;
    public needed_approval: boolean;
    public product_id: number;
    public autoship_item_id: number;
    public total_weight: number;
    public custom: boolean;
    public state: string;
    public created_at: AppDate;
    public updated_at: AppDate;
    public completed_at: AppDate;

    public attrs: Array<Attribute>;

    // derived properties
    public order_key: string;
    public sku_id: number;
    public skus: OrderItemSku[];
    public quantity: number;
    public init_quantity: number;
    public product: Product;
    public financials_total: any;
    public deliveries: OrderItemDelivery[];
    private originalDeliveries: OrderItemDelivery[];
    public notes: Array<Object>;
    public originating_user: Object;
    public vendor: string;
    public tariff_total: number;

    // public allAddresses: Address[];
    public hasSearchAddresses = false;
    public expanded = false;

    public uuid: string;
    public item_cut: boolean;

    public in_stock_dates:  {qty: number, date: AppDate | string}[];
    public backorderedMessage: string;
    public estimatedDates: string;

    public attr_attention: string;

    public changed = false;
    public hidden = false;  // show or hide on order confirmation or order history pages

    public addr_upload_file: string;
    public wslr_upload_file: string;
    public budgetItem: Budget;
    public shipment_timing: string;
    public expected_ship_at: string;

    is_tax_exempt: boolean;
    tax_exempt_reason: number;

    constructor(data: any = {}) {
        super(data, {
            num: ['id', 'order_id', 'entity_id', 'product_id', 'total_weight', 'sku_id', 'quantity', 'tax_exempt_reason'],
            bool: ['needed_approval', 'custom', 'is_tax_exempt'],
            date: ['created_at', 'updated_at', 'completed_at']
        });

        if (data.product) {
            this.product = new Product(data.product);
        }


        if (!this.financials_total) {
           this.financials_total = {
               budget_id: null,
           };
        }
        if ( data.skus) {
            this.skus = data.skus.map( sku => new OrderItemSku(sku));
        } else {
            this.skus = [];
        }

        // _.each(this.skus, (sku, i) => {
        //     this.skus[i] = new OrderItemSku(sku);
        // });
        if (data.deliveries) {
            this.deliveries = data.deliveries.map( delivery => new OrderItemDelivery(delivery));
        } else  {
            this.deliveries = [];
        }

        // keep copy of original deliveries to find  deleted deliveries
        if (data.originalDeliveries) {
            this.originalDeliveries = data.originalDeliveries
        } else {
            this.originalDeliveries = [...this.deliveries.filter( d => !!d.method)];

        }

        if (this.state === 'COMPLETE_STATE') {
            this.state = 'ITEM_COMPLETE_STATE';
        }

        // this.allAddresses = data?.allAddresses || [];
        if (data.uuid) {
            // clone of order item
            this.uuid = data.uuid;
            this.init_quantity = data.init_quantity;
        } else {
            // data received from server
            this.uuid = generateUUID();
            this.init_quantity = this.quantity;
        }

        this.convertInStockDates();
        this.setBackorderMessage();
        this.calculateQuantity();

        if (this.hasTrackingData) {
            // reset shipping timing  and expected_ship_at if tracking data exists
            // this.expected_ship_at = null;
            this.shipment_timing =  null;
            this.backorderedMessage = null;
            this.estimatedDates = null;
        }

        if (this.expected_ship_at) {
            // reset shipping timing if expected_ship_at is defined
            this.shipment_timing = null;

            this.backorderedMessage = '';
            this.estimatedDates = ''
        }

        if (this.backorderedMessage && this.estimatedDates) {
            this.shipment_timing = null;
        }


        // workaround solution
        // TODO remove it after fix on server
        this.setProductAggregatedQuantity();

    }

    public getVariationSKU(skuID: number) {
        let foundSku: any = this.skus.find((sku: OrderItemSku) => sku.sku_id === skuID);

        if (!foundSku) {
            foundSku = new OrderItemSku({id: 0, sku_id: skuID, quantity: 0, price: 0, weight: 0, cartons: 0});
            this.skus.push(foundSku);
        }

        return foundSku;
    }

    public setVariationSKU(sku: OrderItemSku) {
        this.skus = this.skus.map(aSKU => (aSKU.sku_id === sku.sku_id) ? sku : aSKU);
    }

    public getFilters() {
        return this.product.getFilters();
    }

    public get quantityDelta() {
        return Math.max(this.quantity - this.init_quantity, 0);
    }

    public get aggregatedQuantity(): number {
        if (!this.product) {
            return 0;
        }
        return this.product.getAggregatedQuantity();
    }

    // total quantity is a combination of  product aggregated quantity and delta quantity
    public get totalQuantity() {
        return this.aggregatedQuantity + this.quantityDelta;
    }

    public prepItemToSave(): object {
        const deliveriesUUID = this.deliveries.map(d => d.uuid);

        const toBeDeleted = this.originalDeliveries.filter(
            (od) => !deliveriesUUID.includes(od.uuid)
        );

        // sent qty -1 for all deleted shipments
        toBeDeleted.forEach(d => d.quantity = -1);

        const deliveries = [...this.deliveries, ...toBeDeleted].map(delivery => delivery.prepareToSave() );

        // update budget value
        const financials: any = {};
        if (this.financials_total.budget_id) {
            financials.budget_id = this.financials_total.budget_id;
        }
        const obj =  {
            product_id: this.product_id,
            skus: this.skus,
            quantity: this.quantity,
            deliveries,
            financials_total: financials
        };

        if (this.autoship_item_id) {
            obj['autoship_item_id'] =  this.autoship_item_id;
        }

        return obj;
    }

    public computeDeliveryTotalAmount() {

        this.calculateQuantity();

        if (!this.product.hasVariations) {
            if (this.skus && this.skus.length > 0) {
                this.skus[0].quantity = this.quantity;
            }
        }

    }

    /**
     * get actual deliveries that not removed
     */
    public getActualDeliveries() {
        return this.deliveries;
        // return this.deliveries ?
        //     this.deliveries.filter((delivery: OrderItemDelivery) => delivery.pendingDelete !== true) :
        //     [];
    }


    public calculateQuantity() {
        this.quantity = this.getActualDeliveries().reduce( (total, i) => total += i.quantity, 0);

        if (!this.hasVariations) {
            const sku = this.product.firstSku;
            if (sku) {
                const skuId = sku.id;
                const orderItemSku = this.skus.find( s => s.sku_id === skuId);
                if (orderItemSku) {
                    orderItemSku.quantity = this.quantity;
                }
            }
        }
    }

    // get available quantity from provided or first available sku
    public getAvailableQuantity(s?: SKU) {
        const sku = this.getSKU(s);
        return (sku) ? sku.availableQuantity : 0;
    }

    // get pending quantity from provided or first available sku
    public getPendingQuantity(s?: SKU) {
        const sku = this.getSKU(s);
        return (sku) ? sku.pendingQuantity : 0;
    }

    public getInStockQuantity(s?: SKU): number {
        const sku = this.getSKU(s);
        return (sku) ? sku.inStockQuantity : 0;
    }

    private getSKU(sku?: SKU): SKU {
        if (sku) {
            return sku;
        }

        return (this.product) ? this.product.firstSku : null;
    }


    public get maxQuantity(): number {
        return this.product.has_inventory ?
            this.product.getMaxQuantity() :
            Math.max(this.product.getMaxQuantity() - this.totalQuantity, 0);
    }


    public get subTotal(): number {
        return this.financials_total.subtotal - this.financials_total.delivery_total;
    }

    // public get shipping(): number {
    //     return this.financials_total.delivery_total || 0;
    // }

    public get shippingTotal(): number {
        return this.getActualDeliveries().reduce( (total, delivery) => total + delivery.price, 0);
    }

    public get taxes(): number {
        if (this.financials_total) {

            return this.taxExemptIncluded ?
                this.financials_total.exempt_tax_total :
                (this.financials_total.tax_total || 0);
        }
        return  0;
    }

    public get taxExemptIncluded(): boolean {
        return this.taxExempt && this.financials_total.exempt_tax_total != null;
    }

    public get totalCost(): number {
        if (this.financials_total) {
            return ((this.taxExempt && this.financials_total.exempt_grand_total != null) ?
                this.financials_total.exempt_grand_total :
                (this.financials_total.grand_total || 0))  - this.budget ;
        }
        return 0;
    }


    public get discount(): number {
        return this.financials_total.discount_total || 0;
    }

    public get couponDiscount(): number {
        return this.financials_total.coupon_total || 0;
    }

    public get totalDiscount(): number {
        return this.discount + this.couponDiscount;
    }

    public get budget(): number {
        return this.financials_total.budget_total || 0;
    }

    public get maxAvailableQuantity(): number {

        const product = this.product;

        if (product.inventoryType) { // product type INVENTORY_CHILDs
            return product.getAvailableQuantity() + this.init_quantity;
        }


        if (this.product.has_inventory) {
            const maxQuantity = this.maxQuantity + this.init_quantity;
            if (maxQuantity > 0 ) {
                return maxQuantity;
            }
        }
    }



    private setBackorderMessage(): string {
        this.backorderedMessage = '';
        this.estimatedDates = '';

        if (!Array.isArray(this.in_stock_dates) || this.in_stock_dates.length === 0) {
            return;
        }

        // set backorder message
        const arrivingNowCount = this.in_stock_dates.filter( i => i.date === 'now').length;
        if (arrivingNowCount === 0) {
            this.backorderedMessage = 'On Order:';
        } else if (arrivingNowCount < this.in_stock_dates.length) {
            this.backorderedMessage = 'Partially On Order:';
        }

        // set unique dates
        const dates = this.in_stock_dates.map( i => {
            if (typeof  i.date === 'string' ) {
                return 'now';
            }

            if (i.date.isInPast) {
                return 'now';
            }

            return i.date.longDateFormatted;
        }).filter((v, i, a) => a.indexOf(v) === i); // only unique data

        const formattedDates = dates.map( d =>  d === 'now' ? 'Soon' : d).join(' and ');
        const estimated = (dt: string[]) => dt.length > 1 ? 'Estimated Ship Dates' : 'Estimated Ship Date';
        this.estimatedDates = `${estimated(dates)}: ${formattedDates}`;
    }

    private convertInStockDates() {
        if (!this.in_stock_dates) {
            return [];
        }

        this.in_stock_dates = this.in_stock_dates.map( i => {
            return {
                qty: i.qty,
                date: typeof i.date === 'string' ? i.date : new AppDate(i.date)
            }
        });
    }
    public updateDelivery(delivery: OrderItemDelivery | number | string, isOnDemand = false) {
        if (!delivery) {
            return;
        }

        if ( typeof  delivery === 'number') {
            // remove order from list
            this.deliveries =  this.deliveries.filter( i => i.id !== delivery);
        } else if (typeof  delivery === 'string') {
            this.deliveries =  this.deliveries.filter( i => i.uuid !== delivery);
        } else {

            let idx = -1;
            if (delivery.id) {
                idx = this.deliveries.findIndex( i => i.id === delivery.id);
            } else {
                idx = this.deliveries.findIndex( i => i.uuid === delivery.uuid);
            }

            // search by id or addr_id in case id is not defined

            if (idx !== -1) {
                // update order in list
                this.deliveries = Object.assign([...this.deliveries], {[idx]: delivery});
            } else {
                // add order to list
                this.deliveries = [...this.deliveries, delivery];
            }
        }
        this.changed = true;
        this.calculateQuantity();
    }

    // check if the quantity available, otherwise reduce it
    public checkForAvailableItems() {
        if (this.product.type === 'SKU_PRODUCT') {
            return;
        }

        if (!this.product.hasVariations) {

            const availableQuantity = this.product.getAvailableQuantity();
            if (availableQuantity < this.quantity) {
                const ratio = availableQuantity / this.quantity;
                this.deliveries.forEach( d => d.quantity = Math.floor( d.quantity * ratio))
                const totalQty = this.deliveries.reduce( (total, i) => (total += i.quantity), 0);
                this.quantity = totalQty;
                if (this.skus && this.skus.length > 0) {
                    this.skus[0].quantity = totalQty;
                }
            }

        } else {
            this.product.skus.forEach( sku => {
                const orderSku = this.skus.find( s => s.sku_id === sku.id);
                if (orderSku && orderSku.quantity > sku.available_quantity) {
                    orderSku.quantity = sku.available_quantity;
                }
            });


            const totalQty =  this.skus.reduce( (total, i) => (total += i.quantity), 0);
            if (this.deliveries.length > 0) {
                this.deliveries[0].quantity = totalQty;
            }
            this.quantity =  totalQty;
        }
    }


    // public get price(): number {
    //
    //     if (!this.product) {
    //         return 0;
    //     }
    //
    //     if (this.product.hasVariations) {
    //         let itemPrice = 0;
    //         if (Array.isArray(this.skus)) {
    //             this.skus.forEach( sku => {
    //                 const quantity = sku.quantity || 0;
    //                 const productSKU = this.product.getSKUbyID(sku.sku_id);
    //                 itemPrice += this.product.getPrice(quantity, productSKU);
    //             })
    //         }
    //
    //         return itemPrice;
    //     }
    //
    //     if (Array.isArray(this.product.skus) && this.product.skus.length > 0) {
    //         const productSKU = this.product.skus[0];
    //         return this.product.getPrice(this.totalQuantity, productSKU);
    //     }
    //
    //     return 0;
    // }

    public get hasVariations(): boolean {
        if (!this.product) {
            return false;
        }

        return this.product.hasVariations;
    }

    // only for product without variations
    public get productSkuId(): string {
        if (!this.product) {
            return '';
        }
        return this.product.firstSkuId;
    }


    public get isCustomizationDefined(): boolean {
        if (!this.product.isCustomizable) {
            return false;
        }

        return this.getActualDeliveries().map(delivery => {
            return delivery.customizations && delivery.customizations.length > 0;
        }).filter(result => result === true).length === this.getActualDeliveries().length;
    }

    // public get productPrice(): number {
    //     const quantity = (this.order_type === PREORDER_TYPE) ?
    //         this.totalQuantity : this.quantity;
    //
    //     return this.product.getPrice(quantity);
    // }

    public orderedQuantity() {
        return (this.order_type === PREORDER_TYPE) ?
            this.totalQuantity : this.quantity;
    }

    // sku is used for variations
    public getProductPrice(sku?: SKU): number {
        if (!this.orderedQuantity || isEmptyArray(this.skus)) {
            // zero quantity - price should be taken from sku price tiers
            return this.product.getPrice(sku);
        }

        if (!sku) {
            // non variation product
                // no order item sku are defined
            const productPrice  = Number(this.skus[0].product_price);
            return  productPrice ? productPrice : this.product.getPrice(null);

        } else {
            // variations
            // find order item sku
            const orderItemSku = this.skus.find(s => s.sku_id === sku.id);
            if (!orderItemSku) {
                return 0;
            }

            const productPrice  = Number(orderItemSku.product_price);
            return  productPrice ? productPrice : this.product.getPrice(sku);
        }
    }


    // public get groupAggregatedQuantity(): number {
    //     return  (this.product.skus[0].groupMinQTY > 0 && this.product.skus[0].group_aggregated_quantity > -1)
    //         ? this.product.skus[0].group_aggregated_quantity : -1;
    //
    // }

    public get deliveriesTotalQuantity(): number {
        const qty = this.deliveries.reduce((total, delivery) => total + Number(delivery.quantity), 0);

        if (this.skus?.length === 1) {
            this.skus[0].quantity = this.quantity;
        }

        return qty;
    }


    public get customVariationTotalQuantity(): number {
        let customVariationTotalQuantity = 0;
        if (this.product.isCustomizable) {
            this.skus.forEach((sku: OrderItemSku) => {
                customVariationTotalQuantity += sku.quantity;
            });
        }
        return customVariationTotalQuantity;
    }

    public get canPurchase(): boolean {
        if (this.product.isCustomizable &&
            this.customVariationTotalQuantity < this.product.customization.custom_min_qty) {
            return false;
        }

        return this.isAvailable;
    }

    public get isAvailable(): boolean {
        return this.deliveries.length !== 0 &&
            (  this.order_type === ECOMMERCE_TYPE ? this.isODAvailable : this.product.ow_purchasable === true );
    }

    private get isODAvailable(): boolean {
        return  (this.quantityDelta !== 0 || this.product.inStock ) ||
            (!this.product.inStock && this.init_quantity > 0)
    }


    public get isCancelled(): boolean {
        return this.state === VOID_STATE;
    }

    public get isCompleted(): boolean {
        return this.state === COMPLETE_STATE;
    }

    public get isEditable(): boolean {
        return this.state === IN_PROGRESS_STATE;
    }


    public computeAvailableAddresses(isWSLR: boolean) {
        // if (isWSLR) {
        //     this.availableAddresses = [];
        //     return this.availableAddresses;
        // }
        //
        // const selectedAddresses = this.deliveries
        //     .map((delivery) => {
        //         return delivery.getAddress().longAddress;
        //     });
        //
        // this.availableAddresses = this.allAddresses.filter((address) => {
        //     return (selectedAddresses.includes(address.longAddress)) ? null : address;
        // });
        //
        // return this.availableAddresses
    }

    // remove all deliveries that don't have id
    public cleanNewDeliveries() {
        this.deliveries =  this.deliveries.filter( d => d.id > 0);
    }

    public get isCustomOrder(): boolean {
        return this.order_type === CUSTOM_TYPE;
    }

    public get isOnDemandOrder(): boolean {
        return this.order_type === ECOMMERCE_TYPE;
    }

    public get isBuyingWindowOrder(): boolean {
        return this.order_type === PREORDER_TYPE;
    }

    // find delivery by id or uuid
    public findDelivery( id: number | string | Address): OrderItemDelivery {
        if (!id) {
            return null;
        }

        if (id instanceof  Address) {
          return this.deliveries.find( d => d.getAddress().longAddress === id.longAddress);
        } else if (typeof id === 'number') {
            return this.deliveries.find( i => i.id === id);
        } else {
            return this.deliveries.find( i => i.uuid === id);
        }
    }

    public removeDelivery(delivery: number | string | OrderItemDelivery) {
        if (typeof  delivery === 'number' || typeof delivery === 'string') {
            this.updateDelivery(delivery);
        } else {
            this.updateDelivery(delivery.uuid);
        }

    }

    public validateDeliveryQuantity(delivery: OrderItemDelivery, newQuantity: number): number {
        let result = newQuantity;

        if (result > MAX_DELIVERY_QUANTITY) {
            result = MAX_DELIVERY_QUANTITY
        }

        // if (this.product.inventoryType) { // product type INVENTORY_CHILDs
        //
        //     const availableQty = this.product.getAvailableQuantity() + this.init_quantity;
        //
        //     if (availableQty < delivery.quantity) {
        //         result = availableQty;
        //     }
        // }

        if (this.product.has_inventory) {
            const maxQuantity = this.maxQuantity + this.init_quantity;
            if (maxQuantity > 0 && maxQuantity < result) {
                result = maxQuantity;
            }
        }



        return result;

    }

    public validateVariationQuantity(sku: SKU) {
        const itemSKU = this.getVariationSKU(sku.id);

        if (itemSKU.quantity < 0) {
            itemSKU.quantity = 0;
        }

        if (itemSKU.quantity > MAX_DELIVERY_QUANTITY) {
            itemSKU.quantity = MAX_DELIVERY_QUANTITY;
        }

        if (this.product.inventoryType) {
            const availableQty = Math.max((sku.available_quantity + itemSKU.init_quantity) || 0, 0);

            if (availableQty < itemSKU.quantity) {
                itemSKU.quantity = availableQty;
                this.setVariationSKU(itemSKU);
            }
        }

        const quantity = this.skus.reduce( (total, skuItem) => total += skuItem.quantity, 0);

        this.deliveries.forEach( delivery => delivery.quantity = quantity);
        const customizations: Customization[] = this.deliveries[0].customizations;
        if (customizations && customizations.length > 0) {
            customizations[0].custom_qty = quantity;
        }
    }


    public get hasTrimesterBudget(): boolean {
        return this.budgetItem && this.budgetItem.trimester === true;
    }

    public get productDiscount(): number {
        if (this.hasTrimesterBudget) {
            return 0;
        }
        return this.product.discount;
    }


    public resetFinancialTotals() {
        this.financials_total = {
            budget_id: null,
            budget_total: 0,
            coupon_total: 0,
            delivery_total: 0,
            discount_total: 0,
            grand_total: 0,
            product_total: 0,
            subtotal: 0,
            tariff_total: 0,
            tax_total: 0,
        };

        // reset delivery price
        if (!isEmptyArray(this.deliveries)) {
            this.deliveries.forEach( d =>  d.price = 0);
        }
    }

    public get taxExempt(): boolean {
        return this.is_tax_exempt === true;
    }

    public get hasTrackingData(): boolean {
        return this.deliveries.some( d => !!d.tracking_number);
    }

    public get taxExemptReason(): string {
        if (this.taxExempt) {
            return '';
        }

        if (this.tax_exempt_reason === 0 || this.tax_exempt_reason === 1) {
            return 'No Tax Exemption exists on file with this Vendor';
        }

        if (this.tax_exempt_reason === 2) {
            return 'No Tax Exemption exists on file to this location';
        }

        return  '';
    }

    get restricted(): boolean {
        return this.product?.restricted || false;
    }

    // aggregated quantity for on-demand product is not defined yet
    // this is a temporary solution
    public setProductAggregatedQuantity() {
        if (!this.product) {
            return;
        }

        if (this.product.isBuyingWindow) {
            return;
        }

        if (this.product.hasVariations) {
            this.product.skus.forEach( productSKU => {
                const itemSKU = this.skus.find( orderSKU => orderSKU.sku_id === productSKU.id);
                if (!productSKU.aggregated_quantity) {
                    productSKU.aggregated_quantity = itemSKU?.quantity || 0;
                }
            });
        } else {
            if (this.product.firstSku) {
                this.product.firstSku.aggregated_quantity = this.init_quantity;
            }
        }
    }

    public validateAddressesAndShipmentMethodsError(): string {
        if (this.deliveries.some( delivery => !delivery.addr_id)) {
            return  'Shipment address should be selected';
        }

        if (this.deliveries.some( delivery => !delivery.method && delivery.quantity > 0)) {
            return ('Empty shipping method is not allowed');
        }

        return '';

    }

    public containsDeliveryByAddress(address: Address): boolean {
        if (isEmptyArray(this.deliveries)) {
            return false;
        }

        return this.deliveries.some( d =>  {
            return d.quantity > 0  && d.addr_id === address.id && d.address_type === address.address_type;
        });
    }

    public resetOriginalDeliveries() {
        this.originalDeliveries = [...this.deliveries.filter( d => !!d.method)];
    }

    // public get selectedAddresses(): string[] {
    //     return this.deliveries.map( d => d.getAddress().longAddress);
    // }
    //
    // public get availableAddresses(): Address[] {
    //     const selectedAddresses = this.selectedAddresses;
    //
    //     return this.allAddresses.filter( a => {
    //         return !selectedAddresses.includes(a.longAddress)
    //     })
    // }
}
