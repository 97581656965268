import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Observable, of} from 'rxjs';
import {switchMap} from 'rxjs/operators';

import { Order } from '../../../models/order.model';
import { ECOMMERCE_TYPE } from '../../../constants/order-types';
import { OrderService } from '../../../services/order.service';
import { AuthService } from '../../../services/auth.service';
import {ORDER_NOTES_INFO} from '../../../constants/texts';
import {ConfigService} from '../../../services/config.service';
import {MultiTenantService} from '../../../services/multi-tenant.service';
import {Address} from '../../../models/address.model';

@Component({
    selector: 'app-order-settings-modal',
    templateUrl: './order-settings-modal.component.html',
    styleUrls: ['./order-settings-modal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class OrderSettingsModalComponent implements OnInit, OnDestroy {

    shouldUpdateShipments  = false;
    public orderSettingsForm: UntypedFormGroup;
    public label: String;

    public orderName: AbstractControl;
    public notes: AbstractControl;
    public planningComments: AbstractControl;
    public poNumber: AbstractControl;
    public taxExempt: UntypedFormControl;

    private alive = true;

    inProcess = false;

    order: Order;
    orderType: string;
    presetAddresses: Address[];
    isNewOrder = false;

    constructor(private formBuilder: UntypedFormBuilder,
                private orderService: OrderService,
                private authService: AuthService,
                private configService: ConfigService,
                private activeModal: NgbActiveModal,
                private multiTenantService: MultiTenantService) {
    }

    ngOnInit(): void {
        this.isNewOrder = !this.order;
        this.orderSettingsForm = this.formBuilder.group({
            'orderName': ['', Validators.compose([Validators.required])],
            'notes': ['', Validators.compose([])],
            'planningComments': ['', Validators.compose([])],
            'poNumber': ['', Validators.compose([])],
            'taxExempt': ['', Validators.compose([])]
        });
        this.orderName = this.orderSettingsForm.controls['orderName'];
        this.notes = this.orderSettingsForm.controls['notes'];
        this.planningComments = this.orderSettingsForm.controls['planningComments'];
        this.poNumber = this.orderSettingsForm.controls['poNumber'];
        this.taxExempt = this.orderSettingsForm.controls['taxExempt'] as UntypedFormControl;


        if (this.isNewOrder) {
            const newOrderName = this.orderService.getDefaultOrderName(this.orderType);
            this.order = new Order({attr_cart_name: newOrderName});
        }
        this.showDialog(this.order)
    }

    ngOnDestroy(): void {
        this.alive = false;
    }

    showDialog(order: Order) {

        const orderName = order.cartName;
        this.orderName.setValue(orderName);
        this.notes.setValue(order.attr_notes  || '');
        this.poNumber.setValue(order.attr_po_num  || '');
        this.taxExempt.setValue(order.taxExempt  || false);

        if (this.isTaxExemptAllowed === false) {
            this.taxExempt.disable();
        } else {
            this.taxExempt.enable();
        }

        if (this.showPlanningComments) {
            this.planningComments.setValue(order.attr_planning_comments || '');
        }

        this.presetAddresses = order.presetAddresses || [];

    }

    private createOrUpdateOrder(): Observable<Order> {
        if (!this.isNewOrder) {
            return this.updateOrder(this.order);
        }

        const orderLabel = this.orderName.value;
        return this.orderService.createOrder(this.orderType, {orderLabel}).pipe(
            switchMap(retOrder => {
                return this.updateOrder(retOrder);
            })
        )
    }

    private  updateOrder(order: Order): Observable<Order> {
        if (!order) {
            return of(null);
        }

        order.attr_cart_name = this.orderName.value;
        order.attr_po_num = this.poNumber.value;
        order.attr_notes = this.notes.value;
        if (this.showPlanningComments) {
            order.attr_planning_comments = this.planningComments.value || '';
        }


        const attrs = {
            cart_name: this.order.attr_cart_name,
            po_num : this.order.attr_po_num,
            notes : this.order.attr_notes,
        };


        if (this.isTaxExemptAllowed) {
            order.taxExempt = this.taxExempt.value;
            attrs['tax_exempt'] = order.taxExempt.toString();
        }

        if (this.showPlanningComments) {
            attrs['planning_comments'] = order.attr_planning_comments;
        }

        // order re-fetch is in updateOrderDefaultAddresses function
        return this.orderService.setOrderAttributes(order, attrs, false).pipe(
            switchMap( retOrder => {
                if (retOrder) {
                    return this.orderService.updateOrderDefaultAddresses(retOrder, this.presetAddresses, false);
                }
                return of(retOrder);
            })
        );

    }

    saveOrderSettings() {
        this.inProcess = true;
        // order re-fetch is in updateOrderDefaultAddresses function
        this.createOrUpdateOrder().subscribe(data => {
            this.inProcess = false;
            if (data) {
                this.close(data);
            }
        });

    }

    clearManageCartForm() {
        this.orderSettingsForm.reset();
    }


    get isEmployee(): boolean {
        return this.authService.isEmployee;
    }

    get canEditSap(): boolean {
        return this.authService.canShowSAP && this.configService.showBillingInfo;
    }

    get canShowTaxExempt(): boolean {
        return this.authService.isWholeSaler || this.order.isWSLR;
    }

    get isTaxExemptAllowed(): boolean {
        return this.authService.user.isTaxExemptAllowed || this.order.isWSLR;
    }

    onClosed() {
        this.clearManageCartForm();
    }

    close(next: Order | string = null) {
        this.activeModal.close(next);
    }


    get orderLabel() {
        return (this.order && this.order.type === ECOMMERCE_TYPE) ?
            'Cart' :
            'Order'
    }


    get orderIntoPlaceHolder(): string {
        return this.showDeliveryComments ? 'Enter Delivery Comments' : ORDER_NOTES_INFO;
    }

    get showDeliveryComments(): boolean {
        return this.multiTenantService.showDeliveryComments;
    }
    get showPlanningComments(): boolean {
        return this.multiTenantService.showPlanningComments;
    }

    get showOrderNotesOrDeliveryComments(): boolean {
        return this.multiTenantService.showOrderNotesOrDeliveryComments;
    }

    get notesLabel(): string {
        return this.multiTenantService.orderNotesLabel;
    }

    get canEditPresetAddresses(): boolean {
        return true;
    }

    onAddressListChanged(addresses: Address[]) {
        this.presetAddresses = [...addresses];
    }
}
