<div class="modal-md order-item-modal">
    <div class="modal-header state">
        <button type="button" (click)="close()" class="btn btn-icon close" aria-label="Close"><span class="fal fa-times" aria-hidden="true"></span></button>
    </div>
    <div class="modal-body">
        <div class="scroll-container">
            <ng-container *ngIf="showOrderSelector">
                <div class="container order--selector">
                    <app-cart-select [allowAdd]="true" (selectedOrder)="onOrderSelected($event)"></app-cart-select>
                </div>
            </ng-container>

            <app-product-order-item *ngIf="order && orderItem"
                                    [product]="orderItem.product"
                                    [order]="order"
                                    [orderItem]="orderItem"
                                    [delivery]="delivery"
                                    [paidQuantity]="quantity"
                                    [hideFinancials]="hideFinancials"
                                    viewType="list-item"
                                    [showBudget]="!readonly"
                                    [showVariations]="true"
                                    [showTrackingInfo]="readonly"
                                    [showTotals]="true"
                                    [modal]="true"
                                    [readonly]="readonly"
                                    (close)="close()"
                                    (changed)="onOrderItemChanged($event)"
                                    (onUpdate)="handleUpdate($event)"
                                    [isItemCut]="isItemCut"></app-product-order-item>
        </div>
    </div>
</div>
