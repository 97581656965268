<!-- <swiper #thumbsSlider class="product-thumbs" [config]="thumbsSliderConfig" [(index)]="index" *ngIf="product.media">
  <div ngbSlide *ngFor="let media of product.media"><img [src]="media.url" /></div>
</swiper>
<swiper #bigSlider class="product-media" [config]="bigSliderConfig" [(index)]="index" *ngIf="product.media">
  <div ngbSlide *ngFor="let media of product.media"><img [src]="media.url" /></div>
</swiper> -->
<div class="image-wrapper">
    <span *ngIf="!product.is_recommended && product.attr_is_featured > 0" class="tag font-5">Featured</span>
    <span *ngIf="product.is_recommended" class="tag recommended font-5">Recommended</span>
    <div *ngIf="isItemCut" class="cut-error-banner"><span>{{ 'This item has been cut' | uppercase }}</span></div>
    <ngx-gallery [options]="galleryOptions" [images]="galleryImages" [ngClass]="{'mobile' : modalView}"></ngx-gallery>
  </div>
