import {Component, Input, ViewEncapsulation} from '@angular/core';

import {OrderItemDelivery} from '../../../models/order-item-delivery.model';
import {Entity} from '../../../models/entity.model';
import {EntityLocation} from '../../../models/entity-location.model';
import {computeShipmentLabel} from '../../../shared/helpers';

@Component({
    selector: 'app-shipping-label',
    templateUrl: './shipping-label.component.html',
    styleUrls: ['./shipping-label.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ShippingLabelComponent {

    _delivery: OrderItemDelivery;
    entityLabel: string;
    _entity: Entity;

    @Input() attention = '';
    @Input() isDecisionPoint = false;


    @Input() set delivery(val: OrderItemDelivery) {
        this._delivery = val;
    }

    @Input() set wslrEntity(entity: Entity) {
        this._entity = entity;
            this.getLocation();
    }


    private getLocation( ) {
        if (this._entity) {
            const location: EntityLocation = this._entity.locations.find( i => i.id === this._delivery.addr_id);
            this.entityLabel = `WSLR ${this._entity.external_id} - ${location ? (location.label || '') : ''}`;
        }
    }

    constructor() { }



    get label() {
        return computeShipmentLabel(this._delivery);
    }

    get address() {
        return `${this._delivery.street_1}, ${this._delivery.getCSZ() }`;
    }
}
